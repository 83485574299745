import Typography from "typography"

const typography = new Typography({
  baseFontSize: "18px",
  baseLineHeight: 1.666,
  googleFonts: [
    {
      name: 'Lora',
      styles: ['400', '700'],
    },
    {
      name: 'Open Sans',
      styles: ['300', '400', '700'],
    },
  ],
  headerFontFamily: [
    "Lora",
    "Helvetica Neue",
    "Segoe UI",
    "Helvetica",
    "Arial",
    "sans-serif",
  ],
  bodyFontFamily: ["Open Sans", "serif"],
  headerColor: '#ac4142',
  bodyColor: 'hsla(0,0%,0%,0.87)',
  headerWeight: 400,
  bodyWeight: 400,
  boldWeight: 700,

  overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => ({
    a: {
      textDecoration: 'none',
    },
    'h1,h2,h3,h4,h5,h6': {
      marginTop: rhythm(.8),
    },
    'li': {
      marginBottom: rhythm(0.2),
    }
  }),
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
