import React from 'react';
import styled from 'styled-components';

import { Link } from 'gatsby';

import SideBar from '../components/sidebar';
import { rhythm, scale } from '../utils/typography';

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.section`
padding-bottom: ${rhythm};
main {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-right: ${rhythm(1)}
    padding-bottom: ${rhythm(2)};
    padding-top: ${rhythm(2)};
    margin-left: ${rhythm(1)};
    max-width: ${rhythm(40)};
    margin-right: ${rhythm(1)};
  }
}

`;

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;

    return (
      <StyledLayout>
        <SideBar />
        <Content>
          <main>{children}</main>
        </Content>
        <footer
          style={{
            margin: rhythm(0.5),
          }}
        >
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer>
      </StyledLayout>
    );
  }
}

export default Layout;
