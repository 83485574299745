import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const formatIngredient = (ingredient) => {
  return `${ingredient.amount ? ingredient.amount + ' ' : ''}${
    ingredient.name
  }${ingredient.preparation ? ', ' + ingredient.preparation : ''}`;
};

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  images,
  recipe,
  equipment,
  date,
  slug,
  posts,
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const mappedImages =
          images && images.length
            ? images.map((image) => `https://foodimade.com${image}`)
            : [data.site.siteMetadata.siteImage];
        const metaDescription =
          description || data.site.siteMetadata.description;

        const recipeListJson = posts && {
          itemListElement: posts.map(({ node }, idx) => {
            return {
              '@type': 'ListItem',
              position: idx,
              url: `https://foodimade.com/${node.fields.path}/${node.fields.slug}`,
            };
          }),
        };
        const recipeJson = recipe && {
          cookTime:
            recipe.cookTime &&
            'PT' +
              (recipe.cookTime.hours ? recipe.cookTime.hours + 'H' : '') +
              (recipe.cookTime.minutes ? recipe.cookTime.minutes + 'M' : ''),
          prepTime:
            recipe.prepTime &&
            'PT' +
              (recipe.prepTime.hours ? recipe.prepTime.hours + 'H' : '') +
              (recipe.prepTime.minutes ? recipe.prepTime.minutes + 'M' : ''),
          totalTime:
            recipe.cookTime &&
            'PT' +
              (recipe.cookTime.hours
                ? recipe.cookTime.hours + recipe.prepTime.hours + 'H'
                : '') +
              (recipe.cookTime.minutes
                ? recipe.cookTime.minutes + recipe.prepTime.minutes + 'M'
                : ''),
          recipeCategory: recipe.recipeCategory,
          recipeCuisine: recipe.recipeCuisine,
          recipeYield: recipe.recipeYield,
          keywords: recipe.keywords,
          nutrition: recipe.nutrition &&
            recipe.nutrition.calories && {
              '@type': 'NutritionInformation',
              calories: `${page.recipe.nutrition.calories} calories`,
            },
          datePublished: date,
          recipeIngredient:
            recipe.recipeIngredients &&
            recipe.recipeIngredients.map(formatIngredient),
          recipeInstructions: recipe.recipeInstructions.map(
            (instruction, idx) => {
              const stepImage =
                instruction.image &&
                `https://foodimade.com${instruction.image.childImageSharp.sizes.src}`;
              return {
                '@type': 'HowToStep',
                url: `https://foodimade.com/recipe${slug}#step${idx}`,
                name: instruction.name,
                text: instruction.description,
                image: stepImage,
              };
            }
          ),
          description: metaDescription,
          tool: equipment && equipment.map(
            (equipment, idx) => {
              return {
                '@type': 'HowToTool',
                name: equipment,
              };
            }
          ),
          suitableForDiet: recipe.diet && recipe.diet.map(diet => {
            return {
              '@type': 'RestrictedDiet',
              name: diet,
            };
          }),
        };

        const schema = {
          type: 'application/ld+json',
          innerHTML: JSON.stringify(
            Object.assign(
              {
                '@context': 'http://schema.org',
                headline: title,
                name: title,
                '@type': recipe ? 'Recipe' : 'WebPage',
                image: mappedImages,
                author: {
                  '@type': 'Person',
                  name: data.site.siteMetadata.author,
                },
              },
              recipeJson,
              recipeListJson
            )
          ),
        };
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:image`,
                content: mappedImages[0],
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
            script={[schema]}
          ></Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  images: PropTypes.array,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  recipe: PropTypes.object,
  date: PropTypes.string,
  description: PropTypes.string,
  slug: PropTypes.string,
  posts: PropTypes.arrayOf(PropTypes.object),
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        siteUrl
        title
        description
        author
      }
    }
  }
`;
